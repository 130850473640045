
import { defineComponent, computed, onMounted, watch, onUnmounted } from 'vue'
import Button from '@/components/UI/Button.vue'
import toggleableContent from '@/components/toggleableContent.vue'
import InputElement from '@/components/UI/Input.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import FuelType from '@/components/FuelType.vue'
import AddChargingFacilities from '@/components/AddChargingFacilities.vue'
import CarBodyType from '@/components/CarBodyType.vue'
import Checkbox from '@/components/UI/Checkbox.vue'
import CarBrandsModels from '@/components/CarBrandsModels.vue'
import UploadDocument from '@/components/UI/UploadDocument.vue'
import generalInfo from '@/composables/PolicyGeneralInfo'
import PolicyFuelType from '@/composables/PolicyFuelType'
import BodyType from '@/composables/PolicyBodyType'
import PolicyFactoryOptions from '@/composables/PolicyFactoryOptions'
import PolicyBrandsModels from '@/composables/PolicyBrandsModels'
import uploadDocument from '@/composables/PolicyUploadDocument'
import doorCount from '@/composables/PolicyDoorCount'
import VueSlider from 'vue-slider-component'
import Popup from '@/components/Popup.vue'
import { useStore } from '@/store'
import { carPolicyActionTypes } from '@/store/carPolicy/actions'
import { AuthActionTypes } from '@/store/auth/actions'
import { carPolicyMutationTypes } from '@/store/carPolicy/mutations'
import { AuthMutationTypes } from '@/store/auth/mutations'
import { reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import Loader from '@/components/UI/Loader.vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import _ from 'lodash'
import MessageBar from '@/components/UI/MessageBar.vue'
import getCompany from '@/mixins/getCompany'
export default defineComponent({
  components: {
    Button,
    toggleableContent,
    InputElement,
    Dropdown,
    FuelType,
    AddChargingFacilities,
    CarBodyType,
    Checkbox,
    CarBrandsModels,
    UploadDocument,
    VueSlider,
    Loader,
    Popup,
    MessageBar
  },
  name: 'Car policies',
  setup() {
    const store = useStore()
    const route = useRoute()
    const showPopup = ref(false)
    const { t } = useI18n({ useScope: 'global' })
    const carPolicyByID = computed(() => store.state.auth.carPolicyByID)
    const carBrandsModels = computed(() => store.state.options.carBrandsModels)
    const deleteCarPolicyResponse = computed(() => store.state.carPolicy.deleteCarPolicyResponse)
    const documentResponse = computed(() => store.state.carPolicy.documentResponse)
    const carColors = computed(() => store.state.carPolicy.carColors)
    const excludedColorsArray: { value: Array<string> } = reactive({ value: [] })
    const roles = computed(() => store.state.auth.currentUser?.roles)
    const { compId, companyDetailState } = getCompany()
    const closePopup = () => {
      showPopup.value = false
    }
    const onProceed = () => {
      showPopup.value = false
      store.dispatch(carPolicyActionTypes.DELETE_CARPOLICY, {
        companyId: compId,
        carPolicyId: route.params.id
      })
    }
    const {
      colorCodes,
      carPolicyTitle,
      errors,
      selectedColorCode,
      selectColorCode,
      checkStep1,
      onInput
    } = generalInfo()
    const {
      selectedMileageOption,
      selectedDurationOption,
      selectMileageOption,
      selectDurationOption,
      chargingMandatoryOptions,
      selectMandatoryOptions,
      isChargingMandatory,
      chargingFacility,
      addFacility,
      onNameInput,
      onPriceInput,
      deleteFacility,
      step2Errors,
      FuelTypeError,
      selectElectricOption,
      selectHybridOption,
      onCheck,
      checkboxesValues,
      selectedElectricOption,
      selectedHybridOption,
      mandatoryAccessories,
      mandatoryAccessoriesErrors,
      fuelTypeArray,
      convertToNumberAndFloat,
      checkChargingFacility,
      checkAddChargingFacility
    } = PolicyFuelType()
    const { checkboxesStep3, bodyTypeError, onCarBodyCheck, bodyTypeArray } = BodyType()
    const { factoryOptionsCheckboxes, onFactoryCheck, factoryOptionsArray } = PolicyFactoryOptions()
    const {
      models,
      brandModelsArray,
      selectModel,
      removeModel,
      addCarBrand,
      removeAllModels,
      deleteCarBrand,
      getBrands,
      showPolicyBrandsModels,
      selectCarPolicyBrand
    } = PolicyBrandsModels()
    const { fileDetails, fileChange } = uploadDocument()
    const { sliderValue, sliderDataOptions, sliderData } = doorCount()
    const sectionsErrors = reactive({})
    const sectionToggleValues = reactive({ value: [true, false, false, false, false, false, false, false] })
    const loader = computed(() => store.state.carPolicy.loader)
    const onCarColorsCheck = ($event) => {
      if (excludedColorsArray.value.indexOf($event.target.value) === -1) {
        excludedColorsArray.value.push($event.target.value)
      }
      if (!$event.target.checked) {
        excludedColorsArray.value.splice(excludedColorsArray.value.indexOf($event.target.value), 1)
      }
    }
    const initialize = async () => {
      await store.dispatch(AuthActionTypes.GET_CARPOLICY_BY_ID, {
        companyId: compId,
        carPolicyId: route.params.id
      })
      carPolicyTitle.value = carPolicyByID.value.policyTitle
      selectedColorCode.value = carPolicyByID.value.colorCode
      // carBodyTyoe
      checkboxesStep3.forEach((item) => {
        item.isActive = carPolicyByID.value.carBodyType.includes(item.value)
      })
      bodyTypeArray.value = carPolicyByID.value.carBodyType
      // AmountOfDoors
      const minDoors = parseInt(`${carPolicyByID.value.amountOfDoors.min}`)
      sliderValue.value = minDoors
      // mandatory factory
      factoryOptionsCheckboxes.forEach((item) => {
        item.isActive = carPolicyByID.value.mandatoryFactoryOptions.includes(item.title)
      })
      factoryOptionsArray.value = carPolicyByID.value.mandatoryFactoryOptions
      // document
      fileDetails['fileName'] = carPolicyByID.value.document
      // fuel type
      const getIndexOfItem = (selectedTitle) => {
        return checkboxesValues.findIndex((item) => item.title === selectedTitle)
      }
      checkboxesValues.forEach((item) => {
        carPolicyByID.value.fuelType.forEach((policyTitle) => {
          if (policyTitle.title == 'plug_in_hybrid') {
            policyTitle.title = 'plug-in hybrid'
          }
          if (item.title == policyTitle.title) {
            item.isActive = true
            selectedMileageOption[getIndexOfItem(item.title)] = policyTitle.setMileage + ' km'
            selectedDurationOption[getIndexOfItem(item.title)] = policyTitle.setDuration.toString()
            if (item.title == 'electric') {
              let policyDistance = policyTitle.distance.toString()
              if (policyDistance == '1000') {
                policyDistance = '> 1000 km'
              } else if (policyDistance == '0') {
                policyDistance = '< 100 km'
              } else {
                policyDistance = policyDistance + ' km'
              }
              selectedElectricOption.value = policyDistance
              chargingMandatoryOptions[getIndexOfItem('electric')] = policyTitle.isChargingMandatory == 1 ? true : false
            } else if (item.title == 'plug-in hybrid') {
              let policyDistance = policyTitle.distance.toString()
              if (policyDistance == '200') {
                policyDistance = '> 200 km'
              } else if (policyDistance == '0') {
                policyDistance = '< 50 km'
              } else {
                policyDistance = policyDistance + ' km'
              }
              selectedHybridOption.value = policyDistance
              chargingMandatoryOptions[getIndexOfItem('plug-in hybrid')] =
                policyTitle.isChargingMandatory == 1 ? true : false
            }
          }
        })
      })
      fuelTypeArray.value = carPolicyByID.value.fuelType
      // charging facilities
      Array.prototype.unshift.apply(chargingFacility.value, carPolicyByID.value.chargingFacility)
      // mandatory facilities
      mandatoryAccessories.value.unshift(...carPolicyByID.value.mandatoryAccessories)
      for (let i = 0; i < carPolicyByID.value.mandatoryAccessories.length; i++) {
        mandatoryAccessoriesErrors.unshift({
          facilityError: '',
          priceError: ''
        })
      }
      // Car brands models
      if (carPolicyByID.value.carBrandsAndModels.length > 0) {
        showPolicyBrandsModels.value = true
      }
      brandModelsArray.value = carPolicyByID.value.carBrandsAndModels
      // carColors
      excludedColorsArray.value = carPolicyByID.value.excludedCarColors ?? []
    }
    watch(
      () => _.cloneDeep(carBrandsModels.value),
      () => {
        brandModelsArray.value.forEach((item, index) => {
          if (item.brand) {
            const brandName = { item: item.brand, key: index }
            selectCarPolicyBrand(brandName)
          }
        })
      }
    )
    onMounted(async () => {
      if (route.params.id) {
        await initialize()
      } else {
        store.commit(AuthMutationTypes.SET_CARPOLICYBYID, {})
      }
      await store.dispatch(carPolicyActionTypes.GET_CAR_COLORS)
    })
    const toggleSection = (index) => {
      const closeAllOther = sectionToggleValues.value.map(function (item, itemIndex) {
        if (itemIndex === index) {
          item = !item
        } else {
          item = false
        }
        return item
      })
      sectionToggleValues.value = closeAllOther
    }
    // goToNext
    const validateOptions = (options, ErroField) => {
      ErroField.value = ''
      if (!options.some((item) => item.isActive === true)) {
        ErroField.value = 'required'
      } else {
        return true
      }
    }
    const scrollToSection = (sectionId) => {
      const element = document.querySelector(`#${sectionId}`) as HTMLElement
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
    const goToNext = (type, index) => {
      sectionToggleValues['value'][index] = false
      sectionToggleValues['value'][index + 1] = true
      let sectionID = ''
      if (type === 'step1') {
        checkStep1()
        sectionID = 'fuelSection'
      } else if (type === 'step2') {
        validateOptions(checkboxesValues, FuelTypeError)
        if (isChargingMandatory.value) {
          checkChargingFacility()
        }
        sectionID = 'bodyTypeSection'
      } else if (type === 'step3') {
        validateOptions(checkboxesStep3, bodyTypeError)
        sectionID = 'amountOfDoors'
      } else if (type === 'step4') {
        sectionID = 'carBrandsModels'
      } else if (type === 'car-brands') {
        sectionID = 'mandatoryAccessories'
      } else if (type === 'mandatory-accessories') {
        sectionID = 'factoryOptionsSection'
      } else if (type === 'factoryOptionsStep') {
        sectionID = 'uploadDocumentSection'
      }
      setTimeout(() => {
        scrollToSection(sectionID)
      }, 300)
    }
    const validateSections = () => {
      delete sectionsErrors['infoError']
      delete sectionsErrors['fuelTypeError']
      delete sectionsErrors['bodyTypeError']
      delete sectionsErrors['chargingMandatoryFields']
      let sectionId = ''
      let sectionIndex = 0
      if (!checkStep1()) {
        sectionsErrors['infoError'] = 'required'
        sectionId = 'infoSection'
        sectionIndex = 0
      }
      if (!validateOptions(checkboxesValues, FuelTypeError)) {
        sectionsErrors['fuelTypeError'] = 'required'
        if (!sectionId) {
          sectionId = 'fuelSection'
          sectionIndex = 1
        }
      }
      if (isChargingMandatory.value) {
        if (!checkChargingFacility()) {
          sectionsErrors['chargingMandatoryFields'] = 'required'
          if (!sectionId) {
            sectionId = 'fuelSection'
            sectionIndex = 1
          }
        }
      }
      if (!validateOptions(checkboxesStep3, bodyTypeError)) {
        sectionsErrors['bodyTypeError'] = 'required'
        if (!sectionId) {
          sectionId = 'bodyTypeSection'
          sectionIndex = 2
        }
      }
      if (Object.getOwnPropertyNames(sectionsErrors).length === 0) {
        return true
      } else {
        sectionToggleValues['value'][sectionIndex] = true
        scrollToSection(sectionId)
      }
    }
    const getBreadCrumbTitle = () => {
      let title = t('_carPolicy.newCarPolicy')
      if (route.params.id) {
        title = t('_carPolicy.editCarPolicy')
      }
      return title
    }
    const deleteFile = () => {
      fileDetails['file'] = {}
      fileDetails['fileName'] = ''
      store.dispatch(carPolicyActionTypes.DELETE_CARPOLICYDOCUMENT, {
        companyId: compId,
        carPolicyId: carPolicyByID.value.id
      })
    }
    const removeEmptyBrands = (brandsModelsArray) => {
      const tempbrandsModelsArray = JSON.parse(JSON.stringify(brandsModelsArray))
      tempbrandsModelsArray.forEach((item) => {
        if (!item.brand) {
          delete item['brand']
          delete item['models']
        }
      })
      return tempbrandsModelsArray.filter((item) => !(item && Object.keys(item).length === 0))
    }
    const removeType = (type) => {
      const tempFacility = JSON.parse(JSON.stringify(type))
      tempFacility.forEach((item) => {
        delete item['type']
        if ((!item['price'] && !item['chargingFacility']) || (!item['accessory'] && !item['price'])) {
          delete item['price']
          delete item['chargingFacility']
          delete item['accessory']
        }
        if (item['price']) item['price'] = convertToNumberAndFloat(item['price'])
      })
      return tempFacility.filter((item) => !(item && Object.keys(item).length === 0))
    }
    const changeTitle = (fuelTypeArray) => {
      const tempFuelTypeArray = JSON.parse(JSON.stringify(fuelTypeArray))
      const getPlugHybridIndex = fuelTypeArray.findIndex((item) => item.title == 'plug-in hybrid')
      if (getPlugHybridIndex !== -1) {
        tempFuelTypeArray[getPlugHybridIndex].title = 'plug_in_hybrid'
        return tempFuelTypeArray
      } else {
        return fuelTypeArray
      }
    }
    const publishCarPolicy = () => {
      if (validateSections()) {
        const formData = new FormData()
        formData.append('policyTitle', JSON.stringify(carPolicyTitle.value))
        formData.append('colorCode', JSON.stringify(selectedColorCode.value))
        formData.append('fuelType', JSON.stringify(changeTitle(fuelTypeArray.value)))
        formData.append('carBodyType', JSON.stringify(bodyTypeArray.value))
        formData.append('amountOfDoors', JSON.stringify({ min: sliderValue.value }))
        formData.append(
          'carBrandsAndModels',
          JSON.stringify(showPolicyBrandsModels.value ? removeEmptyBrands(brandModelsArray.value) : [])
        )
        formData.append(
          'mandatoryAccessories',
          JSON.stringify(!mandatoryAccessories.value[0]['accessory'] ? [] : removeType(mandatoryAccessories.value))
        )
        formData.append('mandatoryFactoryOptions', JSON.stringify(factoryOptionsArray.value))
        if (fileDetails['file']['name']) formData.append('document', fileDetails['file'] as File)
        formData.append(
          'chargingFacility',
          JSON.stringify(isChargingMandatory.value ? removeType(chargingFacility.value) : [])
        )
        formData.append('excludedColors', JSON.stringify(excludedColorsArray.value))
        if (route.params.id) {
          formData.append('_method', 'PUT')
          store.dispatch(carPolicyActionTypes.UPDATE_CARPOLICY, {
            companyId: compId,
            carPolicyId: carPolicyByID.value.id,
            data: formData
          })
        } else {
          store.dispatch(carPolicyActionTypes.ADD_CARPOLICY, {
            companyId: compId,
            data: formData
          })
        }
      }
    }
    const discardChanges = () => {
      router.push({ name: 'CarPolicyOverview' })
    }
    const deleteCarPolicy = () => {
      showPopup.value = true
    }
    onUnmounted(() => {
      store.commit(carPolicyMutationTypes.DELETE_CARPOLICYRESPONSE, '')
      store.commit(carPolicyMutationTypes.SET_DOCUMENT_RESPONSE, '')
    })
    return {
      colorCodes,
      selectedColorCode,
      selectColorCode,
      goToNext,
      carPolicyTitle,
      errors,
      onInput,
      FuelType,
      checkboxesValues,
      onCheck,
      selectElectricOption,
      selectHybridOption,
      selectedElectricOption,
      selectedHybridOption,
      selectedMileageOption,
      selectedDurationOption,
      selectMileageOption,
      selectDurationOption,
      chargingMandatoryOptions,
      selectMandatoryOptions,
      isChargingMandatory,
      chargingFacility,
      addFacility,
      onNameInput,
      onPriceInput,
      deleteFacility,
      step2Errors,
      checkboxesStep3,
      bodyTypeError,
      FuelTypeError,
      mandatoryAccessories,
      mandatoryAccessoriesErrors,
      factoryOptionsCheckboxes,
      selectCarPolicyBrand,
      models,
      brandModelsArray,
      selectModel,
      removeModel,
      addCarBrand,
      removeAllModels,
      deleteCarBrand,
      showPolicyBrandsModels,
      publishCarPolicy,
      fileDetails,
      fileChange,
      deleteFile,
      onFactoryCheck,
      onCarBodyCheck,
      bodyTypeArray,
      sliderValue,
      sliderDataOptions,
      sliderData,
      factoryOptionsArray,
      convertToNumberAndFloat,
      loader,
      sectionToggleValues,
      toggleSection,
      checkChargingFacility,
      checkAddChargingFacility,
      carPolicyByID,
      getBreadCrumbTitle,
      discardChanges,
      route,
      showPopup,
      closePopup,
      onProceed,
      deleteCarPolicy,
      getBrands,
      deleteCarPolicyResponse,
      documentResponse,
      carColors,
      onCarColorsCheck,
      excludedColorsArray,
      companyDetailState,
      roles
    }
  }
})
