
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  emits: ['toggleSection'],
  props: {
    showError: {
      type: Boolean,
      default: false
    },
    isSectionOpen: {
      type: Boolean,
      default: true
    },
    sectionIndex: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const isOpen = ref(true)
    const route = useRoute()
    const toggleSection = () => {
      if (route.name == 'carPolicy' || route.name == 'Company' || route.name == 'superAdminCompanyDetails') {
        emit('toggleSection', props.sectionIndex)
      } else {
        isOpen.value = !isOpen.value
      }
    }
    return { isOpen, toggleSection }
  }
})
