
import { defineComponent } from 'vue'
import InputElement from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import { chargingFacilityType, mandatoryAccessoriesType, step2ErrorsType } from '@/types'
export default defineComponent({
  props: {
    facility: {
      type: Array as () => chargingFacilityType[],
      default: () => []
    },
    mandatoryAccessories: {
      type: Array as () => mandatoryAccessoriesType[],
      default: () => []
    },
    step2Errors: {
      type: Array as () => step2ErrorsType,
      default: () => [
        {
          facilityError: '',
          priceError: ''
        }
      ]
    },
    othersAccessories: {
      type: Array as () => mandatoryAccessoriesType[]
    },
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    InputElement,
    Button
  },
  setup(props) {
    const getFacilityArray = () => {
      let facilityArray
      if (props.type == 'chargingFacility') {
        facilityArray = props.facility
      } else if (props.type == 'mandatoryAccessories') {
        facilityArray = props.mandatoryAccessories
      } else if (props.type == 'others') {
        facilityArray = props.othersAccessories
      }
      return facilityArray
    }
    const getFacilityProp = () => {
      let facilityType
      if (props.type == 'chargingFacility') {
        facilityType = 'chargingFacility'
      } else {
        facilityType = 'accessory'
      } 
      return facilityType
    }
    return { getFacilityArray, getFacilityProp }
  }
})
