
import Button from '@/components/UI/Button.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    Button
  },
  props: {
    fileDetails: {
      type: Object,
      default: () => ({
        file: {},
        fileName: ''
      })
    }
  },
  emits: ['fileChange', 'deleteFile'],
  setup(props, { emit }) {
    const fileChange = (event) => {
      const fileList = event.target.files
      emit('fileChange', fileList)
    }
    const deleteFile = () => {
      emit('deleteFile')
    }
    return { fileChange, deleteFile }
  }
})
